html, body { height: 100%; }
body { margin: 0;}

.nav-spacer {
  flex: 1 1 auto;
}


.mat-form-field-type-mat-native-select.mat-form-field-disabled .mat-form-field-infix::after, .mat-input-element:disabled {
  color: black !important;
}

.mat-select-disabled .mat-select-value {
  color: black !important;
}

.mat-form-field-infix {
  width: auto !important;
}

.mat-checkbox-layout,
.mat-step-label {
  white-space: normal !important;
}

.mat-form-field-invalid {
  padding-bottom: 25px;
}
